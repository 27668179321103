import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';
import { Observable } from 'rxjs';
import { SearchResult } from '../../../search/models/search-result.model';
import { switchMap, tap } from 'rxjs/operators';
import { AutoCompleteSearchService } from '../../../search/services/auto-complete-search.service';
import { AsyncPipe, NgOptimizedImage } from '@angular/common';
import { TypographyDirective } from '../../directives/typography/typography.directive';
import { TranslateModule } from '@ngx-translate/core';
import { environment } from '@env';

@Component({
    selector: 'app-search-result',
    standalone: true,
    imports: [
        AsyncPipe,
        TypographyDirective,
        TranslateModule,
        NgOptimizedImage
    ],
    providers: [AutoCompleteSearchService],
    templateUrl: './search-result.component.html',
    styleUrl: './search-result.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchResultComponent implements OnInit {
    @Input({ required: true }) searchKey$!: Observable<string>;
    result$!: Observable<SearchResult>;
    @Output() onGetResult = new EventEmitter<void>();
    @Output() onSelectUrlNavigate = new EventEmitter<string>();
    @Output() onSelectSeeMore = new EventEmitter<number>();
    mediaUrl = environment.MEDIA_URL;
    constructor(private autoCompleteSearchService: AutoCompleteSearchService) {}

    ngOnInit() {
        this.initSearchResult();
    }

    initSearchResult() {
        this.result$ = this.searchKey$.pipe(
            switchMap((term) => {
                return this.autoCompleteSearchService
                    .autoCompleteSearch(term)
                    .pipe(
                        tap(() => {
                            this.onGetResult.emit();
                        })
                    );
            })
        );
    }
}
