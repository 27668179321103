@if (result$ | async; as search_results) {
    @if (
        search_results.trek.length ||
        search_results.site.length ||
        search_results.user.length
    ) {
        <div
            class="menu-content"
            [appTypography]="'SECONDARY'">
            @if (search_results.trek.length) {
                <div class="group">
                    <div
                        class="label"
                        [appTypography]="'SECONDARY_BOLD'">
                        {{ 'TREKS' | translate }}
                    </div>
                    @for (trek of search_results.trek; track trek.trek_id) {
                        <div class="option">
                            <a
                                (click)="
                                    onSelectUrlNavigate.emit(
                                        '/trek/' + trek.trek_id
                                    )
                                ">
                                <div>{{ trek.default_trek_name }}</div>
                            </a>
                        </div>
                    }
                    <div class="see-more-container">
                        <div (click)="onSelectSeeMore.emit(1)">
                            {{ 'SEE_MORE' | translate }}
                        </div>
                    </div>
                </div>
            }
            @if (search_results.site.length) {
                <div class="group">
                    <div
                        class="label"
                        [appTypography]="'SECONDARY_BOLD'">
                        {{ 'SITES' | translate }}
                    </div>
                    @for (site of search_results.site; track site.site_id) {
                        <div class="option">
                            <a
                                (click)="
                                    onSelectUrlNavigate.emit(
                                        '/site/' + site.site_id
                                    )
                                ">
                                <div>{{ site.name }}</div>
                            </a>
                        </div>
                    }
                    <div class="see-more-container">
                        <div (click)="onSelectSeeMore.emit(2)">
                            {{ 'SEE_MORE' | translate }}
                        </div>
                    </div>
                </div>
            }
            @if (search_results.user.length) {
                <div class="group">
                    <div
                        class="label"
                        [appTypography]="'SECONDARY_BOLD'">
                        {{ 'USERS' | translate }}
                    </div>
                    @for (user of search_results.user; track user.id) {
                        <div class="option">
                            <a
                                (click)="
                                    onSelectUrlNavigate.emit('/user/' + user.id)
                                ">
                                <img
                                    height="35"
                                    width="35"
                                    alt=""
                                    [ngSrc]="
                                        mediaUrl +
                                        '/profile_picture/' +
                                        user.id +
                                        '/small'
                                    " />
                                <div>{{ user.name }}</div>
                            </a>
                        </div>
                    }
                    <div class="see-more-container">
                        <div (click)="onSelectSeeMore.emit(3)">
                            {{ 'SEE_MORE' | translate }}
                        </div>
                    </div>
                </div>
            }
        </div>
    }
}
